<template>
  <el-container>
    <el-header id="header">
      <whr-header :cy_site="cy_site" @change="handleSelect" />
      <whr-carousel :item="headerItem" />
      <Notice />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <el-footer>
      <whr-footer :cy_site="cy_site" />
    </el-footer>
  </el-container>
</template>
<script>
import { useWindowSize } from "@vueuse/core";
import whrHeader from "./components/whr-header.vue";
import whrFooter from "./components/whr-footer.vue";
import whrCarousel from "./components/whr-carousel.vue";
import Notice from './components/Notice.vue'
import { MenuApi } from "@/api/nav";
import { config,setTenantTag,setBaidu } from "@/config/axios/config";

const { tenant_id } = config;
const getDict = async () => {
  const data = await MenuApi.getDict();
  return data.data;
};
const getSite = async () => {
  const data = await MenuApi.getSite({});
  return data.data;
};
import useUserStore from "@/store/module/user";
const changeDicts = (dicts) => {
  const userStore = useUserStore();
  userStore.changeDicts(dicts);
};
const goToPage = (scode) => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: scode });
};
export default {
  components: {
    whrHeader,
    whrFooter,
    whrCarousel,
    Notice
  },
  data() {
    return {
      wSize: useWindowSize(),
      headerItem: null,
      cy_site: null,
      isFirstEnter: true,
      pathname: '/'
    };
  },
  mounted() {
    setTenantTag();
    setBaidu();
  },
  created() {
    if (this.wSize.width <= 800) {
      this.headerItem = { height: "200px" };
    } else {
      this.headerItem = { height: "65vh" };
    }

    const debounce = (fn, delay) => {
      let timer = null;

      return function () {
        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };

    const _ResizeObserver = window.ResizeObserver;

    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    let cy_site = localStorage.getItem("cy_site");
    if (cy_site) {
      this.cy_site = JSON.parse(cy_site);
    }
    getSite().then((res) => {
      this.cy_site = res;
      localStorage.setItem("cy_site", JSON.stringify(res));
      const descriptionMeta = document.querySelector('meta[name="description"]');
      if (descriptionMeta) {
        descriptionMeta.setAttribute('content', this.cy_site.description);
      }
      const keywordMeta = document.querySelector('meta[name="keyword"]');
      if (keywordMeta) {
        keywordMeta.setAttribute('content', this.cy_site.keywords);
      }
      document.title = this.cy_site.seoTitle;
    });
    getDict().then((res) => {
      changeDicts(res);
    });
    // 首次进入默认全部强制进入首页
    goToPage('1-1')
    this.pathname = window.location.pathname;
    // if (path === "/entercy") {
    //   setTimeout(() => {
    //     goToPage('7-2')
    //   }, 100);
    // }else{
    //   goToPage('1-1')
    // }
    // console.log('path',path)
    
  },
  methods: {
    handleSelect(children, e) {
      // console.log('=====123=====')
      if (e.scode === "1") {
        e.height = "65vh";
        if (this.wSize.width <= 800) {
          e.height = "auto";
        }
      } else {
        e.height = "40vh";
        if (this.wSize.width <= 800) {
          e.height = "auto";
        }
      }
      this.headerItem = e;
      localStorage.setItem("cy_children", JSON.stringify(children));
      localStorage.setItem("cy_activityItem", JSON.stringify(e));
      localStorage.setItem("cy_activityScode", e.scode);
      if (this.isFirstEnter){
        if (this.pathname === "/entercy") {
          goToPage('7-2')
        }
        this.isFirstEnter = false
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/font/fonts.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
.el-header {
  padding: 0% !important;
  height: auto !important;
}
.el-footer {
  padding: 0% !important;
}
.el-main {
  --el-main-padding: 0 !important;
}
// :root {
//   --primaryColor: #0575bc;
// }
.el-button--primary {
  --el-button-bg-color: #0575bc;
}
.whr-scale-hover {
  transition: transform 0.3s ease;
}
.whr-scale-hover:hover {
  transform: scale(0.98);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  background-color: #f4f4f4;
}
</style>
