import { createApp, App as VueApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
const app = createApp(App)

// import { createMetaManager } from 'vue-meta';

// const metaManager = createMetaManager(app, {
//     keyName: 'metaInfo', // 默认值
//     attribute: 'data-vue-meta', // 默认值
//     ssrAttribute: 'data-vue-ssr', // 默认值
//     tagIDKeyName: 'vmid' // 默认值
// });
// app.use(metaManager)


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/styles/global.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

// import { ElCollapseTransition } from 'element-plus'
// fade/zoom
// import 'element-plus/lib/theme-chalk/base.css'
// app.use(ElementPlus)
app.use(ElementPlus, {
    locale: zhCn,
});

import { loadModuleBasedOnCondition } from '@/common/theme.js'
loadModuleBasedOnCondition()

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// import Vant from 'vant';
// import 'vant/lib/index.css';
// app.use(Vant)

import { setupStore } from '@/store'
setupStore(app)
import { getDictValue, getStrDictOptions, getBoolDictOptions } from '@/store/dicts'
app.config.globalProperties.$getDictValue = getDictValue
app.config.globalProperties.$getStrDictOptions = getStrDictOptions
app.config.globalProperties.$getBoolDictOptions = getBoolDictOptions

app.use(router).mount('#app')

