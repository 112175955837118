<template>
  <div class="whr-project-dynamic">
    <el-timeline v-if="dataList.length > 0">
      <el-timeline-item
        v-for="(item, index) in dataList"
        :key="index"
        :timestamp="item.startDate + '&nbsp;&mdash;&nbsp;' + item.endDate"
        placement="top"
        type="primary"
        :hollow="true"
      >
        <el-card>
          <template #header>
            <div class="card-header">
              <span style="color: #0575bc">{{
                getDictValue("ay_progresstype", item.progressType)
              }}</span>
              &nbsp;&nbsp;{{ item.progressTitle }}
              <el-button
                v-if="item.details"
                style="float: right"
                type="primary"
                size="small"
                @click="item.isDetail = !item.isDetail"
                >{{ item.isDetail ? "收起详情" : "查看详情" }}</el-button
              >
            </div>
          </template>
          <!-- <h4>
            <span style="color: #0575bc">{{
              getDictValue("ay_progresstype", item.progressType)
            }}</span>
            &nbsp;&nbsp;{{ item.progressTitle }}
          </h4> -->
          <p>{{ item.progressSummary }}</p>
            
          <el-divider content-position="right" v-if="item.isDetail"
            >进展详情</el-divider
          >
          <el-row v-if="item.isDetail" :gutter="20">
            <el-col :span="12" v-for="(url, index) in item.progressImages" :key='index'>
              <el-image
              :src="url"
              fit="cover"
              style="width: 100%; height: 300px;"
            />
            </el-col>
          </el-row>
          
          <div
            style="margin-top: 10px"
            v-if="item.isDetail"
            v-html="item.details"
          />
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <el-pagination
      class="project-page-pagination"
      :current-page="pageNo"
      :page-size="pageSize"
      :hide-on-single-page="true"
      small="small"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <el-empty v-if="isGetData && dataList.length === 0" description="暂无项目动态">
      <el-button type="primary" @click="getData(1)">点击刷新</el-button>
    </el-empty>
  </div>
</template>
<script>
import { MenuApi } from "@/api/nav";

const getProjectProgressPage = async (params) => {
  const data = await MenuApi.getProjectProgressPage(params);
  return data.data;
};

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.getData(1);
        }
      },
      deep: true,
      immediate: true,
      improjectte: true,
    },
  },
  data() {
    return {
      isGetData: false,
      dataList: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {
    // this.getData(1);
  },
  methods: {
    formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const year = String(date.getFullYear());
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}.${month}.${day}`;
    },
    getDictValue(dictType, value) {
      return this.$getDictValue(dictType, value);
    },
    handleCurrentChange(val) {
      this.getData(val);
    },
    getData(pageNo) {
      // console.log("this.item.id", this.item);
      getProjectProgressPage({
        projectId: this.item.id,
        pageNo: pageNo,
        pageSize: this.pageSize,
        isPublic: true,
      }).then((data) => {
        if (data) {
          this.pageNo = pageNo;
          let list = data.list;
          list.forEach((item) => {
            if (item.startDate && item.endDate){
              item.startDate = this.formatTimestampToDate(item.startDate);
              item.endDate = this.formatTimestampToDate(item.endDate);
            }else if (item.startDate && !item.endDate){
              item.startDate = this.formatTimestampToDate(item.startDate);
              item.endDate = this.formatTimestampToDate(item.startDate);
            }else if (!item.startDate && item.endDate){
              item.startDate = this.formatTimestampToDate(item.endDate);
              item.endDate = this.formatTimestampToDate(item.endDate);
            }else{
              item.startDate = this.formatTimestampToDate(item.createTime);
              item.endDate = this.formatTimestampToDate(item.createTime);
            }
            
            if (item.progressImage){
              item.progressImages = JSON.parse(item.progressImage)
            }
          });
          this.dataList = list;
          this.total = data.total;
          this.isGetData = true
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.whr-project-dynamic {
  text-align: left;
  .card-header {
    font-weight: bold;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
.el-timeline{
  padding: 3px;
}
</style>
