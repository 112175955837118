<template>
  <div
    class="select-content-class"
    :style="wSize.width > 800 ? 'display: flex;' : 'display: block;'"
  >
    <div v-if="wSize.width > 800" :style="wSize.width > 800 ? 'width: 60%' : 'width: 100%'">
      <el-row
        v-for="(item, index) in dataList"
        :key="index"
        style="margin: 5px 0"
        :gutter="20"
      >
        <el-col :span="wSize.width > 800 ? 6 : 12">
          <div style="text-align: left">{{ dealName(item.reciverPayerName) }}</div>
        </el-col>
        <el-col :span="wSize.width > 800 ? 7 : 12">
          <div style="text-align: left">
            {{ formatTimestampToDate(item.paymentTime) }}
          </div>
        </el-col>
        <el-col :span="wSize.width > 800 ? 5 : 12">
          <div style="text-align: left">
            {{ getSymbolWithCurrency(item.currency) }}&nbsp;{{ item.incomeAmount }}
          </div>
        </el-col>
        <el-col :span="wSize.width > 800 ? 6 : 12">
          <div style="text-align: left">{{ item.projectName ? item.projectName : item.parentProjectName }}</div>
        </el-col>
      </el-row>
    </div>
    <div v-if="wSize.width <= 800" class="phone-content-class">
      <transition name="slideup" mode="out-in">
        <p v-if="currentIndex >= 0 && currentIndex < dataList.length">{{ dataList[currentIndex].reciverPayerName}}&nbsp;{{formatTimestampToDate(dataList[currentIndex].paymentTime)}}&nbsp;{{ getSymbolWithCurrency(dataList[currentIndex].currency) }}{{ dataList[currentIndex].incomeAmount }}&nbsp;{{ dataList[currentIndex].projectName ? dataList[currentIndex].projectName : dataList[currentIndex].parentProjectName }}</p>
      </transition>
    </div>
    <!-- <el-button @click="nextText">切换</el-button> -->
    <div
      :style="
        wSize.width > 800
          ? 'width: 40%; display: flex;justify-content: right;'
          : 'width: 100%; display: flex;margin-top: 20px;'
      "
    >
      <el-button
        style="
          flex: 1;
          margin: 0 20px;
          border-radius: 20px;
          font-family: AlimamaShuHeiTi;
          max-width: 150px;
          height:40px;
          background-color: var(--primaryColor);
          border-color: var(--primaryColor);
        "
        color="#0575bc"
        type="primary"
        @click="goToPage('5-1')"
        >捐款查询</el-button
      >
      <el-button
        style="
          flex: 1;
          margin: 0 20px 0 20px;
          border-radius: 20px;
          font-family: AlimamaShuHeiTi;
          max-width: 150px;
          height:40px;
          background-color: var(--primaryColor);
          border-color: var(--primaryColor);
        "
        type="primary"
        @click="goToPage('5-2')"
        >捐物查询</el-button
      >
    </div>
  </div>
</template>
<script setup>
const goToPage = (scode) => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: scode });
};
</script>
<script>
import { useWindowSize } from "@vueuse/core";
import useUserStore from "@/store/module/user";
import { MenuApi } from "@/api/nav";
import {getSymbol} from '@/common/money'
import { nextTick } from "vue";
const getProjectFeedetailPage = async (params) => {
  const data = await MenuApi.getProjectFeedetailPage(params);
  return data.data;
};

export default {
  data() {
    return {
      wSize: useWindowSize(),
      currentIndex: 0,
      dataList: [],
      total: 0,
      pageNo: 1,
      pageSize: 3,
    };
  },
  created() {
    this.getData(1);
    
  },
  methods: {
    dealName(reciverPayerName){
      if (!reciverPayerName || reciverPayerName.length <= 0) return reciverPayerName;
      const firstChar = reciverPayerName.charAt(0);
      if (reciverPayerName.length === 1) {
        return firstChar;
      }
      if (reciverPayerName.length === 2) {
        return firstChar + '*';
      }
      const lastChar = reciverPayerName.charAt(reciverPayerName.length - 1);
      const maskedMiddle = '*'.repeat(reciverPayerName.length - 2);
      return firstChar + maskedMiddle + lastChar;
    },
    nextText() {
      this.currentIndex = (this.currentIndex + 1) % this.dataList.length;
      setTimeout(()=>{
        this.nextText()
      },2000)
    },
    getSymbolWithCurrency(currency){
      let symbol = getSymbol(currency)
      return symbol
    },
    formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const year = String(date.getFullYear());
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    getData(pageNo) {
      getProjectFeedetailPage({
        pageNo: pageNo,
        pageSize: this.pageSize,
        isPublic: true,
      }).then((data) => {
        if (data) {
          this.pageNo = pageNo;
          this.dataList = data.list;
          this.total = data.total;
          this.nextText()
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-content-class {
  color: rgba($color: #000000, $alpha: 0.7);
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2px solid var(--primaryColor);
  margin: 40px var(--padding);
  border-radius: 20px;
}
@media screen and (max-width: 800px) {
    .select-content-class {
      padding: 20px 0;
      margin: 40px 10px;
    }
  }
// .phone-content-class {
//   display: inline-block;
//   position: relative;
//   height: 1em;
// }
// p {
//   position: absolute;
// }

</style>
<style scoped>
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease-out;
}

.slideup-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slideup-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>