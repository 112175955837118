
import { defineStore } from 'pinia'

const useUserStore = defineStore('user', {
    state: () => {
        return {
            scode: undefined,
            dicts: JSON.parse(localStorage.getItem('ay_dicts') || '[]')
        }
    },
    actions: {
        changeScode(scode: any) {
            this.scode = scode
        },
        changeDicts(dicts: any) {
            localStorage.setItem('ay_dicts', JSON.stringify(dicts))
            this.dicts = dicts
        }
    },
    getters: {
        getScode(): any {
            return this.scode
        },
        getDicts(): any {
            return this.dicts
        }
    }
})
export default useUserStore
