import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutcy',
    name: 'aboutcy',
    component: () => import('../views/AboutCY.vue')
  },
  {
    path: '/projectcy',
    name: 'projectcy',
    component: () => import('../views/ProjectCY.vue')
  },
  {
    path: '/organization',
    name: 'organization',
    component: () => import('../views/Organization.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/Message.vue')
  },
  {
    path: '/partybuilding',
    name: 'partybuilding',
    component: () => import('../views/PartyBuilding.vue')
  },
  {
    path: '/entercy',
    name: 'entercy',
    component: () => import('../views/EnterCY.vue')
  },
  {
    path: '/universalm',
    name: 'universalm',
    component: () => import('../views/UniversalM.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
