<template>
  <canvas ref="qrcodeCanvas"></canvas>
</template>
<script>
import QRCode from "qrcode";
export default {
  name: "whr-qrcode",
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      qrcodeDataURL: null,
    };
  },
  watch: {
    text: {
      handler(val) {
        setTimeout(() => {
          this.createQrcode();
        }, 1000);
      },
      deep: true,
      improjectte: true,
    },
  },
  mounted() {
    this.createQrcode();
  },
  methods: {
    createQrcode() {
      if (!this.text) {
        return;
      }
      QRCode.toCanvas(this.$refs.qrcodeCanvas, this.text, {
        margin: 0,
        width: 150,
        height: 150,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      });
    },
  },
};
</script>
