import useUserStore from "@/store/module/user";
const getDictValue = (dictType: string, value: any) => {
    const userStore = useUserStore();
    const dicts = userStore.dicts;
    let label = ''
    dicts.forEach((item: any) => {
        if (item['dictType'] == dictType && item['value'] === value) {
            label = item['label']
        }
    })
    return label
}
const getStrDictOptions = (dictType: string) => {
    const userStore = useUserStore();
    const dicts = userStore.dicts;
    const options: { label: any; value: any; }[] = []
    dicts.forEach((item: any) => {
        if (item['dictType'] == dictType) {
            options.push({
                label: item['label'],
                value: item['value']
            })
        }
    })
    return options
}
const getBoolDictOptions = (dictType: string) => {
    const userStore = useUserStore();
    const dicts = userStore.dicts;
    const options: { label: any; value: boolean; }[] = []
    dicts.forEach((item: any) => {
        if (item['dictType'] == dictType) {
            const value = item['value']
            const boolValue = (value === 'true' ? true : false)
            options.push({
                label: item['label'],
                value: boolValue
            })
        }
    })
    return options
}
export { getDictValue, getStrDictOptions, getBoolDictOptions }