<template>
  <div class="select-content-class">
    <div class="title-class">{{getTenant().name}}伙伴</div>
    <div class="logo-content-class">
      <!-- <el-image
        v-for="(item, index) in dataList"
        :key="index"
        :src="item.logo"
        class="logo-class"
        @click="goToPage(item)"
      /> -->
      <el-image
      v-for="(item, index) in dataList"
        :key="index"
        :src="item.logo"
        fit="content"
        class="logo-class"
        @click="goToPage(item)"
          >
          <template #placeholder>
                  <div class="image-slot">
                    <el-image
                    fit='content'
                      class="logo-class"
                      :src='require("../assets/approve/icon8.jpg")'
                    />
                  </div>
                </template>
            <template #error>
              <div class="image-slot">
                <el-image
                fit='content'
                  class="logo-class"
                  :src='require("../assets/approve/icon8.jpg")'
                />
              </div>
            </template>
          </el-image>
    </div>
  </div>
</template>
<script>
import { MenuApi } from "@/api/nav";
import { getTenant } from '@/config/axios/config'
const getLinkList = async (params) => {
  const data = await MenuApi.getLinkList(params);
  return data.data;
};
export default {
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getTenant(){
      return getTenant()
    },
    getData() {
      getLinkList({
        pageNo: 1,
        pageSize: 100,
      }).then((data) => {
        if (data) {
          this.dataList = data.list;
        }
      });
    },
    goToPage(item) {
      window.open(item.link);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-content-class {
  padding: 40px var(--padding);
  .title-class {
    font-size: 3vw;
    text-align: left;
    width: fit-content;
    border-bottom: 7px solid var(--primaryColor);
  }
  @media screen and (max-width: 800px) {
    // padding: 40px 10%;
    .title-class {
      font-size: 5vw;
    }
  }
  .logo-content-class {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .logo-class {
    margin: 10px 20px;
    cursor: pointer;
    height: 60px;
    width: auto;
    object-fit: contain;
    max-width: 300px;
  }
  
}
</style>
<style>
.el-image__inner{
    object-fit: contain;
  }
</style>
