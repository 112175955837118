<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="whr-header-container"
    ref="whrheadercontainerref"
    v-if="cy_site && cy_site.title"
  >
    <div>
      <el-row>
        <el-col v-if="wSize.width < 800" :span="4" class="header-col-class">
          <el-button
            link
            :icon="Operation"
            class="whr-header-button-class"
            @click="clickShowTop"
            >导航</el-button
          >
        </el-col>
        <el-col :span="wSize.width >= 800 ? 9 : 16" class="header-col-class">
          <el-image
            class="header-logo-class"
            :src="cy_site.logo"
            alt="logo"
            fit="contain"
          />
        </el-col>
        <el-col  v-if="wSize.width >= 800" :span="wSize.width >= 800 ? 15 : 24" class="header-col-class">
          <span class="title">{{ cy_site.title }}</span>
          <!-- <el-input
            v-model="inputValue"
            placeholder="输入关键字"
            class="header-input-class"
            @keyup.enter="handleSearch"
          /> -->
        </el-col>
        <!-- <el-col :span="6" class="header-col-class">
                    
                </el-col> -->
      </el-row>
    </div>
    <nav v-if="wSize.width >= 800">
      <ul class="menu">
        <li
          :class="
            pscode === item.scode ? 'menu-item active-class' : 'menu-item'
          "
          v-for="(item, index) in menuList"
          :key="index"
        >
          <div
            :style="
              wSize.width > 800
                ? ''
                : 'height:100%;display: flex;justify-content: center;align-items: center;'
            "
            @click="handleClick1(item.scode, item.scode, item.children.length)"
          >
            {{ item.name }}
          </div>
          <!-- 如果存在子菜单 -->
          <div v-if="item.children && item.children.length" class="submenu">
            <div
              class="submenu-item"
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
              @click="handleClick(item.scode, child.scode, 0)"
            >
              {{ child.name }}
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <el-drawer
      v-else
      v-model="showTop"
      direction="ltr"
      style="background-color: #0374bc; --el-drawer-padding-primary: 0"
      :with-header="false"
      size="40%"
    >
      <el-menu
        :default-active="activeIndex"
        class="whr-header-menu-class"
        text-color="#ffffffdd"
        active-text-color="#fff"
        @select="handleSelect"
      >
        <template v-for="item in menuList" :key="item.scode">
          <el-menu-item
            style="background-color: #ffffff00"
            v-if="item.children.length === 0"
            :index="item.scode"
            :key="item.scode"
            >{{ item.name }}</el-menu-item
          >
          <el-sub-menu :index="item.scode" v-else>
            <template #title>{{ item.name }}</template>
            <el-menu-item
              style="background-color: #ffffff00"
              :index="item2.scode"
              v-for="item2 in item.children"
              :key="item2.scode"
            >
              <template #title>{{ item2.name }}</template>
            </el-menu-item>
          </el-sub-menu>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>
<script setup>
import { Operation } from "@element-plus/icons-vue";
</script>
<script>
import { useWindowSize } from "@vueuse/core";
// import { computed, watch } from "vue";
import useUserStore from "@/store/module/user";
import router from "../router";
import { MenuApi } from "@/api/nav";
const getMenuData = async () => {
  const data = await MenuApi.getMenuPage({});
  let list = data.data;
  let plist = [];
  list.forEach((item) => {
    if (item.pcode === "0") {
      let children = [];
      list.forEach((item2) => {
        if (item2.pcode === item.scode) {
          children.push(item2);
        }
      });
      item.children = children;
      plist.push(item);
    }
  });
  return plist;
};
export default {
  props: {
    cy_site: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      wSize: useWindowSize(),
      showTop: false,
      bgColor: "#0374BC",
      activeIndex: "1",
      pscode: "1", //父级编码,用于保持header item 选中状态
      menuList: [],
      routeList: [
        {
          path: "/",
          scodes: [
            "1",
            "1-1",
            "1-2",
            "1-3",
            "1-4",
            "1-5",
            "1-6",
            "1-7",
            "1-8",
            "1-9",
            "1-10",
          ],
          name: "首页",
        },
        {
          path: "/aboutcy",
          scodes: [
            "2",
            "2-1",
            "2-2",
            "2-3",
            "2-4",
            "2-5",
            "2-6",
            "2-7",
            "2-8",
            "2-9",
            "2-10",
          ],
        },
        {
          path: "/projectcy",
          scodes: [
            "3",
            "3-1",
            "3-2",
            "3-3",
            "3-4",
            "3-5",
            "3-6",
            "3-7",
            "3-8",
            "3-9",
            "3-10",
          ],
        },
        {
          path: "/organization",
          scodes: [
            "4",
            "4-1",
            "4-2",
            "4-3",
            "4-4",
            "4-5",
            "4-6",
            "4-7",
            "4-8",
            "4-9",
            "4-10",
          ],
        },
        {
          path: "/message",
          scodes: [
            "5",
            "5-1",
            "5-2",
            "5-3",
            "5-4",
            "5-5",
            "5-6",
            "5-7",
            "5-8",
            "5-9",
            "5-10",
          ],
        },
        {
          path: "/partybuilding",
          scodes: [
            "6",
            "6-1",
            "6-2",
            "6-3",
            "6-4",
            "6-5",
            "6-6",
            "6-7",
            "6-8",
            "6-9",
            "6-10",
          ],
        },
        {
          path: "/entercy",
          scodes: [
            "7",
            "7-1",
            "7-2",
            "7-3",
            "7-4",
            "7-5",
            "7-6",
            "7-7",
            "7-8",
            "7-9",
            "7-10",
          ],
        },
      ],
      inputValue: "",
      intervalId: null,
    };
  },

  created() {
    getMenuData().then((data) => {
      this.menuList = data;
    });
  },
  mounted() {
    const userStore = useUserStore();
    this.intervalId = setInterval(() => this.updateScode(userStore), 500);
  },
  methods: {
    clickShowTop() {
      this.showTop = true;
    },
    updateScode(userStore) {
      let scode = userStore.scode;
      if (scode) {
        if (this.$refs.anchorRef) {
          this.$refs.anchorRef.scrollTo("#header");
        }

        let param = JSON.parse(JSON.stringify(scode));
        userStore.changeScode(undefined);
        this.pscode = String(param.scode).split("-")[0];
        this.handleSelect(param.scode, param.id);
      }
    },
    handleClick1(pscode, scode, children) {
      if (children === 0) {
        this.pscode = pscode;
        this.handleSelect(scode);
      }else{
         
        this.menuList.forEach((item) => {
          if (item.scode === pscode) {
            if (item.children.length > 0){
              scode = item.children[0].scode
            }
          }
        })
        this.handleClick(pscode,scode,0)
      }
    },
    handleClick(pscode, scode, children) {
      this.pscode = pscode;
      
      if (children === 0) {
        this.handleSelect(scode);
      }
    },
    handleSelect(e, id) {
      let cy_scode = "1";
      let children = [];
      this.menuList.forEach((item) => {
        if (item.scode === e) {
          children = [item];
          cy_scode = item.scode;
        } else {
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              if (item2.scode === e) {
                children = item.children;
                cy_scode = item.scode;
              }
            });
          }
        }
      });
      let isFind = false;
      this.routeList.forEach((item) => {
        if (item.scodes.includes(e)) {
          this.activeIndex = e;
          item.scode = this.activeIndex;
          let tempItem = JSON.parse(JSON.stringify(item));
          let tempChildren = JSON.parse(JSON.stringify(children));
          if (id) {
            tempChildren.forEach((ele) => {
              if (ele.scode === this.activeIndex) {
                ele.jid = id;
              }
              ele.time = new Date().getTime();
            });
            tempItem.jid = id;
          }
          isFind = true;
          this.$emit("change", tempChildren, tempItem);
          router.push(tempItem.path);
        }
      });
      if (isFind === false) {
        this.$emit("change", children, { path: "/universalm", scodes: ["*"] });
        router.push("/universalm");
      }
      localStorage.setItem("cy_scode", cy_scode);
      this.showTop = false;
    },
    handleSearch(e) {},
  },
};
</script>
<style scoped lang="scss">
.whr-header-container {
  .whr-header-button-class {
    margin-left: 0;
    margin-right: auto;
  }
  .header-col-class {
    display: flex;
    justify-content: center;
    align-items: center;

    .header-logo-class {
      width: 300px;
      height: 100%;
      //margin: 20px 60px;
    }

    .title {
      font-size: 2vw;
      font-weight: 400;
      color: var(--primaryColor);
      width: 100%;
      font-family: AlimamaShuHeiTi;
    }
    .header-input-class {
      border-color: #0374bc;
      border-width: 4px;
      width: 20%;
      margin: 0 60px 0 auto;
    }
    @media screen and (max-width: 800px) {
      .header-logo-class {
        // margin: 20px auto 10px 0;
        width: 150px;
        float: left;
      }
      .title {
        // font-size: 18px;
        font-size: 18px;
        font-weight: bold;
      }
      .header-input-class {
        display: none;
      }
    }
  }
  .whr-header-menu-class {
    font-family: AlimamaShuHeiTi;
    background-color: #ffffff00;
    .is-active {
      // background-color: #fff;
    }
  }
}
</style>
<style>
.el-menu-item {
  font-family: AlimamaShuHeiTi !important;
}
.el-menu {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.el-sub-menu__title:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.menu {
  list-style-type: none;
  display: flex;
  padding: 0 var(--padding);
  margin: 0;
  /* height: 60px; */
  background-color: var(--primaryColor);
  font-size: 16px;
}

.menu-item {
  display: inline-block;
  font-weight: bold;
  margin: 0 10px;
  padding: 10px 0;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  width: 110px;
  color: #fff;
  border-radius: 0;
}
.active-class {
  background-color: #fff;
  color: var(--primaryColor);
  /* border-radius: 10px; */
}
.menu-item:hover {
  background-color: #fff;
  color: var(--primaryColor);
  border-radius: 0;
}

.submenu {
  list-style-type: none;
  z-index: 999;
  position: absolute;
  display: none;
  background-color: #fff;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
  width: 110px;
  margin-top: 0px;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  
}
.submenu-item {
  text-align: center;
  margin: 10px 10px;
  color: #000;
  height: 30px;
  line-height: 30px;
  /* height: 25px;
  line-height: 25px; */
}
.submenu-item:hover {
  color: var(--primaryColor);
}

.menu li:hover > .submenu {
  display: block;
}
</style>
