<template>
  <div class="whr-home-project-class" v-if="dataList.length > 0">
    <div class="title-class">{{getTenant().name}}故事</div>
    <el-row class="content-class" :gutter="wSize.width > 800 ? 40 : 0">
      <el-col
        :span="wSize.width > 800 ? 8 : 24"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div
          class="whr-scale-hover"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 20px;
            overflow: hidden;
          "
          @click="goToPage(item)"
        >
          <el-image :src="item.ico" alt="" fit="cover" class="project-page-img">
            <template #placeholder>
                  <div class="image-slot">
                    <el-image
                      class="project-page-img"
                      :src='require("../assets/approve/icon7.jpg")'
                      fit="cover"
                    />
                  </div>
                </template>
            <template #error>
              <div class="image-slot">
                <el-image
                  class="project-page-img"
                  :src='require("../assets/approve/icon7.jpg")'
                  fit="cover"
                />
              </div>
            </template>
          </el-image>
          <el-icon
            class="project-page-icon"
            :size="60"
            color="#fff"
            v-if="isVideoUrl(item.enclosure)"
            ><icon-view
          /></el-icon>
        </div>
        <div class="project-page-title">{{ item.title }}</div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import useUserStore from "@/store/module/user";
const goToPage = (item) => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: item.scode, id: item.id });
};
</script>
<script>
import { useWindowSize } from "@vueuse/core";
// import router from "../router";
import { VideoPlay as IconView } from "@element-plus/icons-vue";
import { MenuApi } from "@/api/nav";
import { common } from "@/common";
import { getTenant } from '@/config/axios/config'
const getContentList = async (params) => {
  const data = await MenuApi.getContentList(params);
  return data.data;
};
export default {
  components: {
    IconView,
  },
  data() {
    return {
      wSize: useWindowSize(),
      dataList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    isVideoUrl(enclosure) {
      return common.isVideoUrl(enclosure);
    },
    formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    getData() {
      getContentList({
        scode: "4-5",
        pageNo: 1,
        pageSize: 3,
      }).then((data) => {
        if (data) {
          //   const groups = [];
          //   for (let i = 0; i < data.list.length; i += 2) {
          //     groups.push(data.list.slice(i, i + 2));
          //   }
          this.dataList = data.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.whr-home-project-class {
  background-color: #fff;
  padding: 40px var(--padding);
  .title-class {
    font-size: 3vw;
    text-align: left;
    width: fit-content;
    border-bottom: 7px solid var(--primaryColor);
  }
  @media screen and (max-width: 800px) {
    // padding: 40px 10%;
    .title-class {
      font-size: 5vw;
    }
  }
  .content-class {
    margin-top: 40px;
  }
  .project-page-img {
    //   width: 419px;
    aspect-ratio: 4/3;
    // height: 240px;
    margin: 0;
    
  }
  .project-page-icon {
    position: absolute;
  }
  .project-page-title {
    font-size: 18px;
    // height: 30px;
    line-height: 30px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
