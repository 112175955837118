<template>
    <el-dialog append-to-body title="通知公告" v-model="isShow" width="60%">
        <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item, index) in dataList" :title="item.title" :name="String(index)">
            <div v-html='item.content'></div>
        </el-collapse-item>
      </el-collapse>
      <template #footer>
        <el-button @click="isShow = false">关 闭</el-button>
    </template>
    </el-dialog>
</template>
<script>
import { MenuApi } from "@/api/nav";
const getContentData = async () => {
 const data = await MenuApi.getContentList({scode:'4-1',isShowDialog:true});
 return data.data;
};
export default {
 name: "Feedback",
 data() {
   return {
       isShow: false,
       activeNames: '0',
       dataList:[]
   };
 },
 mounted() {
   this.getData();
 },
 methods: {
    getData(){
        getContentData().then((data) => {
            if (data.list) {
                this.dataList = data.list;
                if (this.dataList.length > 0){
                    this.isShow = true
                }
            }
        })
    }
 }
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>
