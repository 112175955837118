<template>
  <div>
    <template v-if="item.projectOverview">
      <div class="project-sub-title">项目概述</div>
      <div style="text-align: left;">{{ item.projectOverview }}</div>
    </template>

    <template v-if="item.background">
      <div class="project-sub-title">项目背景</div>
      <div v-html="item.background"></div>
    </template>

    <template v-if="item.introduction">
      <div class="project-sub-title">项目详情</div>
      <div v-html="item.introduction"></div>
    </template>

    <template v-if="item.activities">
      <div class="project-sub-title">项目活动</div>
      <div v-html="item.activities"></div>
    </template>

    <template v-if="item.fundraisingLink">
      <div class="project-sub-title">筹款链接</div>
      <whr-qrcode :text="item.fundraisingLink" />
      <div
        class="project-link-title"
        v-if="canCopy"
        @click="copy(item.fundraisingLink)"
      >
        点击复制链接
      </div>
      <div class="project-link-title" v-else>
        复制该链接:{{ item.fundraisingLink }}
      </div>
    </template>
  </div>
</template>
<script>
import whrQrcode from "./whr-qrcode.vue";
export default {
  components: {
    whrQrcode,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.project-sub-title {
  font-size: 18px;
  text-align: left;
  margin: 40px 0;
  font-weight: bold;
  color: grey;
  font-family: AlimamaShuHeiTi;
}
.project-link-title {
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
  // font-weight: bold;
  color: green;
  // background-color: lightgray;
  padding: 10px;
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}
</style>
