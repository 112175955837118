import request from '@/config/axios'

// 网站 API
export const MenuApi = {
  // 查询字典类型列表
  getDict: async () => {
    return await request.get({ url: `/system/dict-data/type?type=` })
  },
  // 获取文件预签名地址
  getFilePresignedUrl: async (path: string) => {
    return await request.get({
      url: '/infra/file/presigned-url',
      params: { path }
    })
  },
  // 创建文件
  createFile: async (data: any) => {
    return await request.post({ url: '/infra/file/create', data })
  },

  // 上传文件
  updateFile: async (data: any) => {
    return await request.upload({ url: '/infra/file/upload', data })
  },
  // 查询网站导航分页
  getMenuPage: async (params: any) => {
    return await request.get({ url: `/ay/menu/list`, params })
  },
  // 查询网站导航分页
  getSlidePage: async (params: any) => {
    return await request.get({ url: `/ay/slide/list`, params })
  },
  // 查询网站内容单页
  getContentPage: async (params: any) => {
    return await request.get({ url: `/ay/content/list-by-scode`, params })
  },
  // 查询网站内容分页
  getContentList: async (params: any) => {
    return await request.get({ url: `/ay/content/page`, params })
  },
  // 查询理事会与监事内容分页
  getLeaderList: async (params: any) => {
    return await request.get({ url: `/ay/leader/list`, params })
  },
  // 查询官网基础信息
  getSite: async (params: any) => {
    return await request.get({ url: `/ay/site/get`, params })
  },
  // 查询项目列表分页
  getProjectList: async (params: any) => {
    params.reviewStatus = 2
    return await request.get({ url: `/ay/project/page`, params })
  },
  // 获得项目进展分页
  getProjectProgressPage: async (params: any) => {
    params.reviewStatus = 2
    return await request.get({ url: `/ay/project/project-progress/page`, params })
  },
  // 获得项目收支总额
  getProjectFeedetailSuminamount: async (params: any) => {
    return await request.get({ url: `/ay/project/project-feedetail/get-suminamount`, params })
  },
  // 获得项目收支明细分页
  getProjectFeedetailPage: async (params: any) => {
    return await request.get({ url: `/ay/project/project-feedetail/page`, params })
  },
  // 获得项目收支明细分页 非单个项目查询模块
  getProjectFeedetailPage2: async (params: any) => {
    return await request.get({ url: `/ay/project/project-feedetail/page2`, params })
  },

  // 获得全部收支明细分页
  getFeedetailPage: async (params: any) => {
    return await request.get({ url: `/ay/project-feedetail/page`, params })
  },
  // 查询友情链接分页
  getLinkList: async (params: any) => {
    return await request.get({ url: `/ay/link/page`, params })
  },
  // 订阅邮件
  subscriptionEmail: async (params: any) => {
    return await request.post({ url: `/ay/email/create`, params })
  },
  // 新增个案信息
  createCaseInfo: async (data: any) => {
    return await request.post({ url: `/ay/case-info/create`, data })
  },
  // 新增志愿者信息
  createCyUser: async (data: any) => {
    return await request.post({ url: `/ay/cyuser/create`, data })
  },
  // 新增用户反馈
  createFeedback: async (data: any) => {
    return await request.post({ url: `/ay/feedback/create`, data })
  },
}