<template>
     <el-dialog append-to-body title="意见反馈" v-model="isShow" width="80%">
        <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-width="100px"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="formData.userName" placeholder="请输入用户名" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="formData.phone" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="formData.email" placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="反馈内容" prop="content">
        <el-input type="textarea" v-model="formData.content" placeholder="请输入反馈内容"/>
      </el-form-item>
    </el-form>
    <template #footer>
        <el-button type="primary" @click="btnClick">确 认</el-button>
        <el-button @click="isShow = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { MenuApi } from "@/api/nav";
const createFeedback = async (params) => {
  const data = await MenuApi.createFeedback(params);
  return data.data;
};
export default {
  name: "Feedback",
  data() {
    return {
        isShow: false,
        formData:{
            userName:undefined,
            phone:undefined,
            email:undefined,
            content:undefined
        },
        formRules:{
            userName:[
                {required:true,message:'请输入用户名',trigger:'blur'}
            ],
            phone:[
                {required:true,message:'请输入手机号',trigger:'blur'}
            ],
            email:[
                {required:true,message:'请输入邮箱',trigger:'blur'}
            ],
            content:[
                {required:true,message:'请输入反馈内容',trigger:'blur'}
            ]
        }
    };
  },
  methods: {
    show() {
        console.log(this.isShow);
      this.isShow = true
    },
    btnClick(){
        let self = this
        this.$refs.formRef
        .validate()
        .then((res) => {
            createFeedback(this.formData).then((data) => {
                self.$message.success('提交成功')
                self.isShow = false
            })
        }).catch((err) => {
          this.$message({
            message: "反馈信息不完整,请补充完整再提交",
            type: "error",
          });
        });
    }
  }
};
</script>