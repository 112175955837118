<template>
  <div
    class="whr-carousel"
    id="whrcarousel"
    :style="'height:' + height"
    v-if="imagelist.length > 0"
  >
    <el-image
      v-if="imagelist.length === 1"
      :style="'width: 100%;height:' + height + ';'"
      :src="imagelist[0].pic"
      fit="cover"
    />
    <el-carousel v-else :height="height" motion-blur :interval="5000">
      <el-carousel-item v-for="item in imagelist.length" :key="item">
        <el-image
          style="width: 100%; height: 100%"
          :src="imagelist[item - 1].pic"
          fit="cover"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MenuApi } from "@/api/nav";

export default defineComponent({
  name: "whrCarousel",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val && val.height) {
          this.height = val.height;
        }
        this.uploadList();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      height: "65vh",
      allImageList: [],
      imagelist: <any>[],
    };
  },
  created() {},
  methods: {
    uploadList() {
      this.imagelist = [];
      let cy_scode = localStorage.getItem("cy_scode");
      if (!cy_scode) {
        cy_scode = "1";
      }
      MenuApi.getSlidePage({ scode: cy_scode }).then((res) => {
        this.allImageList = res.data;
        this.allImageList.forEach((item: any) => {
          if (item.scode === cy_scode) {
            this.imagelist.push(item);
          }
        });
        console.log(this.imagelist);
      });
    },
  },
});
</script>
<style scoped>
.whr-carousel {
  width: 100%;
  /* height: 100% !important; */
}
</style>
