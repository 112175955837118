import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "whr-footer-content-class"
}
const _hoisted_2 = { class: "whr-footer-message-class" }
const _hoisted_3 = { class: "title-class" }
const _hoisted_4 = { class: "title-class" }
const _hoisted_5 = { class: "whr-footer-social-class" }
const _hoisted_6 = {
  style: {"display":"flex"},
  class: "phone-class"
}
const _hoisted_7 = { class: "bottom-class" }
const _hoisted_8 = { class: "bottom-class" }
const _hoisted_9 = { class: "bottom-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!

  return ($props.cy_site)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: $data.wSize.width > 800 ? 12 : 24
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_image, {
                    src: $props.cy_site.footerTitle1,
                    class: "logo-class"
                  }, null, 8, ["src"]),
                  _createElementVNode("div", {
                    style: _normalizeStyle($data.wSize.width > 800 ? 'display: flex;' : '')
                  }, [
                    _createElementVNode("div", _hoisted_3, "联系电话: " + _toDisplayString($props.cy_site.contactPhone), 1),
                    _createElementVNode("div", {
                      class: "title-class",
                      style: _normalizeStyle($data.wSize.width > 800 ? 'margin-left: 30px' : '')
                    }, " 联系邮箱: " + _toDisplayString($props.cy_site.contactEmail), 5)
                  ], 4),
                  _createElementVNode("div", _hoisted_4, "联系地址: " + _toDisplayString($props.cy_site.address), 1)
                ])
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, {
              span: $data.wSize.width > 800 ? 12 : 24,
              style: {"display":"flex"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-class" }, "关注我们，了解实时动态", -1)),
                  _createElementVNode("div", _hoisted_6, [
                    ($props.cy_site.socialWx)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 0,
                          content: 
                $options.getToolTipContent($props.cy_site.socialWx, '扫码关注微信公众号')
              ,
                          "raw-content": "",
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: require('@/assets/7.png'),
                              fit: "",
                              class: "social-logo"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true),
                    ($props.cy_site.socialSina)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 1,
                          content: 
                $options.getToolTipContent($props.cy_site.socialSina, '扫码关注新浪微博')
              ,
                          "raw-content": "",
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: require('@/assets/8.png'),
                              fit: "",
                              class: "social-logo"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true),
                    ($props.cy_site.socialWxvideo)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 2,
                          content: 
                $options.getToolTipContent($props.cy_site.socialWxvideo, '扫码关注微信视频号')
              ,
                          "raw-content": "",
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: require('@/assets/9.png'),
                              fit: "",
                              class: "social-logo"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true),
                    ($props.cy_site.socialDouyin)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 3,
                          content: 
                $options.getToolTipContent($props.cy_site.socialDouyin, '扫码关注抖音账号')
              ,
                          "raw-content": "",
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: require('@/assets/10.png'),
                              fit: "",
                              class: "social-logo"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true),
                    ($props.cy_site.socialTaobao)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 4,
                          content: 
                $options.getToolTipContent($props.cy_site.socialTaobao, '扫码关注淘宝账号')
              ,
                          "raw-content": "",
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: require('@/assets/11.png'),
                              fit: "",
                              class: "social-logo"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["span"]),
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "line-class" }, null, -1)),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: $data.wSize.width > 800 ? 8 : 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", {
                            class: "bottom-text",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goToUrl('https://beian.miit.gov.cn')))
                          }, _toDisplayString($props.cy_site.icp), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["span"]),
                    _createVNode(_component_el_col, {
                      span: $data.wSize.width > 800 ? 8 : 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("span", {
                            class: "bottom-text",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goToUrl('https://beian.mps.gov.cn')))
                          }, _toDisplayString($props.cy_site.domain), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["span"]),
                    _createVNode(_component_el_col, {
                      span: $data.wSize.width > 800 ? 8 : 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", null, _toDisplayString($props.cy_site.copyright), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["span"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}