import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_ctx.imagelist.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "whr-carousel",
        id: "whrcarousel",
        style: _normalizeStyle('height:' + _ctx.height)
      }, [
        (_ctx.imagelist.length === 1)
          ? (_openBlock(), _createBlock(_component_el_image, {
              key: 0,
              style: _normalizeStyle('width: 100%;height:' + _ctx.height + ';'),
              src: _ctx.imagelist[0].pic,
              fit: "cover"
            }, null, 8, ["style", "src"]))
          : (_openBlock(), _createBlock(_component_el_carousel, {
              key: 1,
              height: _ctx.height,
              "motion-blur": "",
              interval: 5000
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imagelist.length, (item) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, { key: item }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_image, {
                        style: {"width":"100%","height":"100%"},
                        src: _ctx.imagelist[item - 1].pic,
                        fit: "cover"
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["height"]))
      ], 4))
    : _createCommentVNode("", true)
}