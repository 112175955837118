<template>
  <div class="home">
    <el-affix :offset="300" style="width: 70px; position: absolute">
      <el-anchor
        
        ref="anchorRef"
        :offset="70"
        :style="'background-color: rgba(0, 0, 0, 0);opacity:'  + (wSize.width > 800 ? 1 : 0) + ';'"
      >
        <el-anchor-link href="#header" :title="getTenant().name + '首页'"></el-anchor-link>
        <el-anchor-link
          href="#whrHomeProject"
          :title="getTenant().name + '项目'"
        ></el-anchor-link>
        <el-anchor-link href="#whrHomeNews" title="新闻动态"></el-anchor-link>
        <el-anchor-link href="#whrHomeStory" :title="getTenant().name + '故事'"></el-anchor-link>
        <el-anchor-link href="#whrHomeLink" :title="getTenant().name + '伙伴'"></el-anchor-link>
      </el-anchor>
    </el-affix>
    <whrHomeTool id="whrHomeTool" @scrollLink="anchorScroll"></whrHomeTool>
    <whrHomeSelect id="whrHomeSelect"></whrHomeSelect>
    <whrHomeProject id="whrHomeProject"></whrHomeProject>
    <whrHomeNews id="whrHomeNews"></whrHomeNews>
    <whrHomeStory id="whrHomeStory"></whrHomeStory>
    <whrHomeLink id="whrHomeLink"></whrHomeLink>
    <whrHomeSubscription id="whrHomeSubscription"></whrHomeSubscription>
    
  </div>
</template>

<script>
// import { Options, Vue } from "vue-class-component";
import { useWindowSize } from "@vueuse/core";
import whrHomeSelect from "@/components/whr-home-select.vue";
import whrHomeProject from "@/components/whr-home-project.vue";
import whrHomeNews from "@/components/whr-home-news.vue";
import whrHomeStory from "@/components/whr-home-story.vue";
import whrHomeLink from "@/components/whr-home-link.vue";
import whrHomeSubscription from "@/components/whr-home-subscription.vue";
import whrHomeTool from "@/components/whr-home-tool.vue";
import { getTenant } from '@/config/axios/config'
export default {
  components: {
    whrHomeSelect,
    whrHomeProject,
    whrHomeNews,
    whrHomeStory,
    whrHomeLink,
    whrHomeSubscription,
    whrHomeTool,
  },
  data() {
    return {
      wSize: useWindowSize(),
    };
  },
  methods: {
    getTenant(){
      return getTenant()
    },
    anchorScroll(anchorName) {
      this.$refs.anchorRef.scrollTo(anchorName);
    },
  },
};
</script>
<style>
.el-anchor__link.is-active {
    color: var(--primaryColor) !important;
}
.el-anchor__marker {
    background-color:var(--primaryColor) !important;
}
</style>
