<template>
  <div>
    <div class="suminamount-class" v-if="suminamount !== 0">
      <div class="title-class">{{suminamount}}</div>
      <div class="desc-class">已筹金额/元</div>
    </div>
    <el-table
      v-if="dataList.length > 0"
      :data="dataList"
      stripe
      :header-cell-style="{ 'background-color': '#fafafa' }"
    >
      <el-table-column prop="reciverPayerName" label="捐赠方">
      </el-table-column>
      <el-table-column prop="paymentTime" label="日期">
        <template #default="scope">
          {{ formatTimestampToDate(scope.row.paymentTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="incomeAmount" label="金额">
        <template #default="scope">
          {{ scope.row.incomeAmount }}({{ scope.row.currency }})
        </template>
      </el-table-column>
      <el-table-column prop="projectName" label="公益项目">
        <template #default="scope">
          {{ scope.row.projectName ? scope.row.projectName : scope.row.parentProjectName }}
        </template>
      </el-table-column>
      <el-table-column prop="sourceDescription" label="支付方式">
      </el-table-column>
    </el-table>
    <el-pagination
      class="project-page-pagination"
      :current-page="pageNo"
      :page-size="pageSize"
      :hide-on-single-page="true"
      small="small"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <el-empty v-if="isGetData && dataList.length === 0" description="暂无捐赠数据">
      <el-button type="primary" @click="getData(1)">点击刷新</el-button>
    </el-empty>
  </div>
</template>
<script>
import { MenuApi } from "@/api/nav";
const getProjectFeedetailPage = async (params) => {
  const data = await MenuApi.getProjectFeedetailPage2(params);
  return data.data;
};
const getProjectFeedetailSuminamount= async (params) => {
  const data = await MenuApi.getProjectFeedetailSuminamount(params);
  return data.data;
};
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.getData(1);
          // this.getSuminamount()
        }
      },
      deep: true,
      immediate: true,
      improjectte: true,
    },
  },
  data() {
    return {
      isGetData: false,
      dataList: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      suminamount: 0
    };
  },
  created() {
    // this.getData(1);
  },
  methods: {
    formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const year = String(date.getFullYear());
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    handleCurrentChange(val) {
      this.getData(val);
      // this.getSuminamount()
    },
    getSuminamount(){
      getProjectFeedetailSuminamount({'projectId':this.item.id}).then((data)=>{
        this.suminamount = Number(data)
      })
    },
    getData(pageNo) {
      getProjectFeedetailPage({
        projectId: this.item.id,
        pageNo: pageNo,
        pageSize: this.pageSize,
        isPublic: true,
      }).then((data) => {
        if (data) {
          this.pageNo = pageNo;
          this.dataList = data.list;
          this.total = data.total;
          this.isGetData = true
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.suminamount-class{
    background-color: #f2f2f2;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0 40px 0;
    // height: 100px;
    padding: 20px 0;
    .title-class{
      font-size: 40px;
      font-weight: bold;
      color: var(--primaryColor);
      height: 40px;
      line-height: 40px;
    }
    .desc-class{
      font-size: 16px;
      color: rgb(162, 162, 162);
      height: 20px;
      line-height: 20px;
      margin-top: 10px;
    }
  }
.project-page-pagination {
  margin-top: 20px;
}
</style>
