<template>
  <div class="whr-home-project-class" :style="'height:' + (carouselitemWidth + 50) + 'px;'">
    <div class="title-class">{{getTenant().name}}项目</div>
    <el-carousel :height="carouselitemWidth + 'px'" autoplay style="margin-top: 40px">
      <el-carousel-item v-for="(items, index) in dataList" :key="index">
        <div style="display: flex" ref="carouselitem">
          <div
            :class="
              wSize.width > 800
                ? 'project-page-content whr-scale-hover'
                : 'project-page-content width-class'
            "
            v-for="(item, index2) in items"
            :key="item.id"
            :style="
              index2 % 2 === 0 ? 'margin-right: 20px' : 'margin-left: 20px'
            "
             @click="goToPage(item)"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-image
                :src="item.pcImageUrl"
                alt=""
                fit="cover"
                class="project-page-img"
              >
              <template #placeholder>
                  <div class="image-slot">
                    <el-image
                      class="project-page-img"
                      :src='require("../assets/approve/icon9.jpg")'
                      fit="cover"
                    />
                  </div>
                </template>
              <template #error>
              <div class="image-slot">
                <el-image
                  class="project-page-img"
                  :src='require("../assets/approve/icon9.jpg")'
                  fit="cover"
                />
              </div>
            </template>
              </el-image>
              <div class="status-class">{{ getDictValue('ay_status_project',String(item.state)) }}</div>
              <el-icon
                class="project-page-icon"
                :size="60"
                color="#fff"
                v-if="isVideoUrl(item.pcImageUrl)"
                ><icon-view
              /></el-icon>
            </div>
            
            <div class="project-page-title">{{ item.projectName }}</div>
            <div class="project-page-projectOverview">
              <p>{{ item.projectOverview }}</p>
            </div>
            <div v-if="wSize.width > 800" class="project-page-btn" @click="goToPage(item)">查看详情</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div v-if="wSize.width <= 800" class="project-page-btn2" @click="goToPage2">查看更多 >></div>
    <whr-project-page-single
      ref="single"
      v-if="isSignle"
      :item="item"
      @close="isSignle = false"
    />
  </div>
</template>
<script setup>
import useUserStore from "@/store/module/user";

const goToPage = (item) => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: "3-1", id: item.id });
};
const goToPage2 = () => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: "3-1"});
};
</script>
<script>
import { useWindowSize } from "@vueuse/core";
// import router from "../router";
import { VideoPlay as IconView } from "@element-plus/icons-vue";
import { MenuApi } from "@/api/nav";
import whrProjectPageSingle from "./whr-project-page-single.vue";
import { common } from "@/common";
import { getTenant } from '@/config/axios/config'
const getProjectList = async (params) => {
  const data = await MenuApi.getProjectList(params);
  return data.data;
};
export default {
  components: {
    whrProjectPageSingle,
    IconView,
  },
  mounted() {
    this.carouselitemWidth = (window.innerWidth / 2 - window.innerWidth * 0.1) / 16 * 9 + (window.innerWidth * 0.1 < 80 ? 300 : window.innerWidth * 0.19);
  },
  data() {
    return {
      carouselitemWidth:600,
      wSize: useWindowSize(),
      isSignle: false,
      dataList: [],
      isAutonom: undefined,
    };
  },
  created() {
    this.getDataAsync()
  },
  methods: {
    getDataAsync(){
      const userStore = useUserStore();
      if (userStore.dicts.length === 0){
        setTimeout(()=>{
          this.getDataAsync()
        },500)
      }else{
        this.getData();
      }
    },
    getDictValue(dictType, value) {
      return this.$getDictValue(dictType, value);
    },
    isVideoUrl(enclosure) {
      return common.isVideoUrl(enclosure);
    },
    getData() {
      getProjectList({
        scode: "3-1",
        pageNo: 1,
        pageSize: 12,
        isAutonom: undefined,
        isFundraising: false,
        isPublic: true,
        isHome: 1
      }).then((data) => {
        if (data) {
          const groups = [];
          if (this.wSize.width > 800) {
            for (let i = 0; i < data.list.length; i += 2) {
              groups.push(data.list.slice(i, i + 2));
            }
          } else {
            for (let i = 0; i < data.list.length; i += 2) {
              groups.push(data.list.slice(i, i + 1));
            }
          }

          this.dataList = groups;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.whr-home-project-class {
  background-color: #fafafa;
  padding: 20px var(--padding) 0 var(--padding);
  
  .title-class {
    font-size: 3vw;
    text-align: left;
    width: fit-content;
    border-bottom: 7px solid var(--primaryColor);
  }
  @media screen and (max-width: 800px) {
    padding: 40px var(--padding);
    .title-class {
      font-size: 5vw;
    }
  }
  .width-class {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .project-page-content {
    width: 50%;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    .project-page-img {
      //   width: 419px;
      width: 100%;
      aspect-ratio: 16 / 9;
      // height: 382px;
      // border-radius: 30px;
    }
    .status-class{
      position: absolute;
      top: 20px;
      left: 0px;
      background-color: #fafafa;
      color: var(--primaryColor);
      font-weight: bold;
      padding: 5px 10px 5px 20px;
      border-radius: 0 4px 4px 0;
    }
    .project-page-icon {
      position: absolute;
    }
    .project-page-title {
      font-size: 2vw;
      // height: 50px;
      // line-height: 50px;
      text-align: left;
      font-family: AlimamaShuHeiTi;
      margin: 20px 20px 10px 20px;
    }
    
    @media screen and (max-width: 800px) {
      .project-page-title {
        font-size: 4vw;
        // height: 50px;
        // line-height: 50px;
        text-align: left;
        font-family: AlimamaShuHeiTi;
        margin: 20px 20px 10px 20px;
      }
      .project-page-projectOverview {
        font-size: 3vw;
        margin: 0 20px 10px 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        color: #6c6c6c;
        text-align: left;
      }
    }
    .project-page-projectOverview p {
      flex: 1;
      line-height: 1.5;
      margin: 0 20px 10px 20px;
      text-align: left;
    }
    .project-page-projectOverview p {
      max-height: 3em; /* 1.5 * 2 */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 对 WebKit 浏览器的支持 */
      -webkit-box-orient: vertical;
      height: 48px;
    }
    .project-page-btn {
      cursor: pointer;
      border: 1px solid var(--primaryColor);
      color: var(--primaryColor);
      font-family: AlimamaShuHeiTi;
      border-radius: 20px;
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  .project-page-btn2 {
      cursor: pointer;
      // border: 1px solid var(--primaryColor);
      color: var(--primaryColor);
      // font-family: AlimamaShuHeiTi;
      font-weight: bold;
      border-radius: 20px;
      width: 40%;
      height: 32px;
      line-height: 30px;
      position: relative;
      top: -70px;
      left: 30%;
    }
}
</style>
