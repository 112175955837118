<template>
  <div class="home-tool">
    <div style="margin-top: 4px" @click="goToPage('3-4')">
      <el-image
        class="image-class"
        :src="require('../assets/3.png')"
        fit="contain"
      />
    </div>
    <div v-if="wSize.width > 800" style="margin-top: 4px" @click="scrollLink('#header')">
      <el-image
        class="image-class"
        :src="require('../assets/4.png')"
        fit="contain"
      />
    </div>
    <div style="margin-top: 4px" @click="goToPage('7-2')">
      <el-image
        class="image-class"
        :src="require('../assets/5.png')"
        fit="contain"
      />
    </div>
    <div style="margin-top: 4px" @click="scrollLink('#whrHomeLink')">
      <el-image
        class="image-class"
        :src="require('../assets/6.png')"
        fit="contain"
      />
    </div>
    <div style="margin-top: 4px" @click="clickFeedback">
      <el-image
        class="image-class"
        :src="require('../assets/12.png')"
        fit="contain"
      />
    </div>
    <feedback ref="feedback" />
  </div>
</template>
<script setup>
import useUserStore from "@/store/module/user";
import { useWindowSize } from "@vueuse/core";
const goToPage = (scode) => {
  const userStore = useUserStore();
  userStore.changeScode({ scode: scode });
};
</script>
<script>
import feedback from "@/components/Feedback.vue"
export default {
  name: "HomeTool",
  components: {
    feedback
  },
  data() {
    return {
      wSize: useWindowSize()
    };
  },
  methods: {
    scrollLink(name) {
      this.$emit("scrollLink", name);
    },
    clickFeedback(){
      console.log(this.$refs.feedback);
      this.$refs.feedback.show();
    }
  },
};
</script>
<style lang="scss">
.home-tool {
  width: 70px;
  margin: 10px 0 20px 0;
  padding: 20px;
  // border-radius: 10px;
  // height: 190px;
  position: fixed;
  right: 10px;
  top: 40%;
  z-index:999;
  .image-class {
    width: 70px;
    height: 70px;
    cursor: pointer;
    background-color: var(--primaryColor);
    border-radius: 10px;
  }
}
@media screen and (max-width: 800px) {
  .home-tool {
    position: relative;
    display: flex;
    overflow-x: scroll;
    background-color: #f8f8f8;
    // margin-left: 10px;
    width: auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .image-class {
      width: 50px;
      height: 50px;
      cursor: pointer;
      // margin: 0 5px;
      background-color: var(--primaryColor);
      border-radius: 10px;
    }
  }
  
}
</style>
