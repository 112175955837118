<template>
  <div class="project-page-single-class">
    <el-button type="" text style="left: 0; position: absolute" @click="goBack">
      <el-icon class="el-icon--back"><icon-view /></el-icon>
      返回
    </el-button>
    <div
      class="project-page-content"
      style="padding-top: 55px"
      v-for="item in dataList"
      :key="item.id"
    >
      <el-row :gutter="wSize.width > 800 ? 40 : 0">
        <el-col :span="wSize.width > 800 ? 10 : 24">
          <video
            :src="item.enclosure"
            controls
            autoplay
            v-if="isVideoUrl(item.pcImageUrl)"
            class="project-page-img"
          ></video>
          <img v-else :src="item.pcImageUrl" alt="" class="project-page-img"
        /></el-col>
        <el-col :span="wSize.width > 800 ? 14 : 24">
          <div style="position: relative; height: 100%">
            <div class="project-page-title">
              <!-- <span v-if="item.projectAbbreviation">【{{ item.projectAbbreviation }}】&mdash;&mdash;&nbsp;&nbsp;</span> -->
              {{
                item.projectName
              }}
            </div>
            <div class="project-page-desc" style="margin-top: 10px">
              {{ item.projectTarget }}
            </div>
            <!-- <div class="flex-class">
            <span>关键词:</span>
            <span>脊柱健康,脊柱侧弯</span>
          </div> -->
            <div class="project-page-desc" v-if="item.beianNo">
              <span>备案编号:</span>
              <span>{{ item.beianNo }}</span>
            </div>
            <div class="project-page-desc" v-if="item.projectCategory">
              <span>项目类型:</span>
              <span style="color: var(--primaryColor)">{{
                getDictValue("ay_project", item.projectCategory)
              }}</span>
            </div>
            <div class="project-page-desc" style="margin-bottom: 40px;">
              <span>募捐起止:</span>
              <span
                >{{ item.approvalStartDate ?  formatTimestampToDate(item.approvalStartDate) : '' }}-{{
                  item.approvalEndDate ? formatTimestampToDate(item.approvalEndDate) : ''
                }}</span
              >
            </div>
            <div class="project-page-btn">
              <el-button
                v-if="item.fundraisingLink"
                type="primary"
                @click="goToUrl(item.fundraisingLink)"
                >我要捐赠</el-button
              >
              <el-button
                :style="item.fundraisingLink ? 'margin: 0 0 0 20px' : ''"
                type="primary"
                plain
                @click="goToPage('7-2')"
                >成为志愿者</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" style="margin-top: 20px">
        <el-tab-pane label="项目详情" name="1" key="1">
          <whr-project-detail :item="item" />
          <el-pagination
            class="project-page-pagination"
            :current-page="pageNo"
            :page-size="pageSize"
            :hide-on-single-page="true"
            small="small"
            background
            layout="prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </el-tab-pane>
        <el-tab-pane label="项目动态" name="2" key="2">
          <whr-project-dynamic :item="item" />
        </el-tab-pane>
        <el-tab-pane label="捐赠查询" name="3" key="3">
          <whr-project-donation :item="item" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-empty v-if="isGetData && dataList.length === 0" description="暂无数据">
      <el-button type="primary" @click="getData(1)">点击刷新</el-button>
    </el-empty>
  </div>
</template>
<script>
import useUserStore from "@/store/module/user";
import { ElMessage } from "element-plus";
import { Back as IconView } from "@element-plus/icons-vue";
import { MenuApi } from "@/api/nav";
import { common } from "@/common";
import whrProjectDetail from "@/components/whr-project-detail";
import whrProjectDynamic from "@/components/whr-project-dynamic";
import whrProjectDonation from "@/components/whr-project-donation";
import { useWindowSize } from "@vueuse/core";
const getProjectList = async (params) => {
  const data = await MenuApi.getProjectList(params);
  return data.data;
};
export default {
  components: {
    IconView,
    whrProjectDetail,
    whrProjectDynamic,
    whrProjectDonation,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      wSize: useWindowSize(),
      isGetData: false,
      dataList: [],
      total: 0,
      pageNo: 1,
      pageSize: 1,
      activeName: "1",
      isAutonom: undefined,
      canCopy: true,
    };
  },
  methods: {
    getDictValue(dictType, value) {
      return this.$getDictValue(dictType, value);
    },
    goToPage(scode) {
      const userStore = useUserStore();
      userStore.changeScode({ scode: scode });
    },
    formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}.${month}.${day}`;
    },
    isVideoUrl(enclosure) {
      return common.isVideoUrl(enclosure);
    },
    show(pageNo) {
      this.getData(pageNo);
    },
    getData(pageNo) {
      if (this.item.scode === "3-1") {
        this.isAutonom = undefined;
      } else if (this.item.scode === "3-2") {
        this.isAutonom = true;
      }
      if (this.item.scode === "3-3") {
        this.isAutonom = false;
      }
      getProjectList({
        scode: this.item.scode,
        pageNo: pageNo,
        pageSize: this.pageSize,
        isAutonom: this.isAutonom,
        isFundraising: false,
        isPublic: true,
      }).then((data) => {
        if (data) {
          this.pageNo = pageNo;
          this.dataList = data.list;
          this.total = data.total;
          this.isGetData = true
        }
      });
    },
    goBack() {
      this.$emit("close");
    },
    handleCurrentChange(val) {
      this.getData(val);
    },
    async copy(fundraisingLink) {
      try {
        await navigator.clipboard.writeText(fundraisingLink);
        ElMessage({
          message: "复制成功",
          type: "success",
        });
      } catch (err) {
        ElMessage({
          message: "复制失败",
          type: "error",
        });
        this.canCopy = false;
        console.error("复制失败", err);
      }
    },
    goToUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style scoped lang="scss">
.project-page-single-class {
  padding: 10px var(--padding);
  .project-page-content {
    .project-page-img {
      border-radius: 30px;
      // margin: 10px;
      width: -webkit-fill-available;
      width: -moz-available;
    }
   
    .project-page-title {
      font-size: 24px;
      font-weight: 400;
      color: var(--primaryColor);
      text-align: left;
      font-weight: bold;
      font-family: AlimamaShuHeiTi;
      white-space: nowrap;
    }
    .project-page-desc {
      text-align: left;
      font-size: 18px;
      line-height: 38px;
      font-weight: 400;
      margin-left: 0px;
    }
    .project-page-btn {
      display: flex;
      left: 0;
      right: 0;
      bottom: 5px;
      position: absolute;
    }
    @media screen and (max-width: 800px) {
      .project-page-img {
        border-radius: 0px;
        }
        .project-page-title {
          margin-top:20px;
        }
        .project-page-desc {
          margin-left: 0px;
        }
    }
  }
  .project-page-pagination {
    width: fit-content;
    margin: auto;
  }
}
</style>
