import { config } from '@/config/axios/config'
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
const { base_url, request_timeout, tenant_id } = config

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL: base_url, // api 的 base_url
  timeout: request_timeout, // 请求超时时间
  withCredentials: false // 禁用 Cookie 等信息
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['tenant-id'] = tenant_id
    return config
  }
)
export { service }
