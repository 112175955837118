<template>
  <div class="whr-footer-content-class" v-if="cy_site">
    <el-row>
      <el-col :span="wSize.width > 800 ? 12 : 24">
        <div class="whr-footer-message-class">
          <el-image :src="cy_site.footerTitle1" class="logo-class"></el-image>
          <div :style="wSize.width > 800 ? 'display: flex;' : ''">
            <div class="title-class">联系电话: {{ cy_site.contactPhone }}</div>
            <div
              class="title-class"
              :style="wSize.width > 800 ? 'margin-left: 30px' : ''"
            >
              联系邮箱: {{ cy_site.contactEmail }}
            </div>
          </div>
          <div class="title-class">联系地址: {{ cy_site.address }}</div>
        </div>
      </el-col>
      <el-col :span="wSize.width > 800 ? 12 : 24" style="display: flex">
        <div class="whr-footer-social-class">
          <div class="title-class">关注我们，了解实时动态</div>
          <div style="display: flex" class="phone-class">
            <el-tooltip
              v-if="cy_site.socialWx"
              :content="
                getToolTipContent(cy_site.socialWx, '扫码关注微信公众号')
              "
              raw-content
              placement="top"
            >
              <el-image
                :src="require('@/assets/7.png')"
                fit=""
                class="social-logo"
              ></el-image>
            </el-tooltip>
            <el-tooltip
              v-if="cy_site.socialSina"
              :content="
                getToolTipContent(cy_site.socialSina, '扫码关注新浪微博')
              "
              raw-content
              placement="top"
            >
              <el-image
                :src="require('@/assets/8.png')"
                fit=""
                class="social-logo"
              ></el-image>
            </el-tooltip>
            <el-tooltip
              v-if="cy_site.socialWxvideo"
              :content="
                getToolTipContent(cy_site.socialWxvideo, '扫码关注微信视频号')
              "
              raw-content
              placement="top"
            >
              <el-image
                :src="require('@/assets/9.png')"
                fit=""
                class="social-logo"
              ></el-image>
            </el-tooltip>
            <el-tooltip
              v-if="cy_site.socialDouyin"
              :content="
                getToolTipContent(cy_site.socialDouyin, '扫码关注抖音账号')
              "
              raw-content
              placement="top"
            >
              <el-image
                :src="require('@/assets/10.png')"
                fit=""
                class="social-logo"
              ></el-image>
            </el-tooltip>
            <el-tooltip
              v-if="cy_site.socialTaobao"
              :content="
                getToolTipContent(cy_site.socialTaobao, '扫码关注淘宝账号')
              "
              raw-content
              placement="top"
            >
              <el-image
                :src="require('@/assets/11.png')"
                fit=""
                class="social-logo"
              ></el-image>
            </el-tooltip>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="line-class"></div>
        <el-row>
          <el-col :span="wSize.width > 800 ? 8 : 24">
            <div class="bottom-class">
              <span
                class="bottom-text"
                @click="goToUrl('https://beian.miit.gov.cn')"
                >{{ cy_site.icp }}</span
              >
            </div>
          </el-col>
          <el-col :span="wSize.width > 800 ? 8 : 24">
            <div class="bottom-class">
              <span
                class="bottom-text"
                @click="goToUrl('https://beian.mps.gov.cn')"
                >{{ cy_site.domain }}</span
              >
            </div>
          </el-col>
          <el-col :span="wSize.width > 800 ? 8 : 24">
            <div class="bottom-class">
              <span>{{ cy_site.copyright }}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { useWindowSize } from "@vueuse/core";
export default {
  props: {
    cy_site: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      wSize: useWindowSize(),
    };
  },
  methods: {
    goToUrl(url: string) {
      window.open(url);
    },
    getToolTipContent(url: string, title: string) {
      return (
        '<p style="text-align: center;"><img src=' +
        url +
        ' width="120" height="120" style="width: 120px; height: 120px;"/></p><p style="text-align: center;"><span style="color: rgb(79, 129, 189);">' +
        title +
        "</span></p>"
      );
    },
  },
};
</script>
<style scoped lang="scss">
.whr-footer-content-class {
  background-color: var(--primaryColor);
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding-top: 40px;
  padding: 40px var(--padding) 0 var(--padding);
  .whr-footer-message-class {
    text-align: left;
    .logo-class {
      width: 60%;
      // height: 77px;
      margin: 20px 0;
    }
    .title-class {
      margin: 10px 0;
      font-size: 18px;
    }
  }
  .whr-footer-social-class {
    text-align: left;
    margin-bottom: 0;
    margin-top: auto;
    margin-left: var(--padding);
    .title-class {
      margin: 10px 20px;
      font-family: AlimamaShuHeiTi;
    }
    .social-logo {
      width: 50px;
      height: 50px;
      margin: 20px;
      cursor: pointer;
    }
    
  }
  
  .line-class {
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    margin: 20px 0 0 0;
  }
  .bottom-class {
    font-size: 15px;
    // font-weight: 400;
    padding: 20px 0;
    font-family: AlimamaShuHeiTi;
  }
  .bottom-class p {
    font-size: 15px;
  }
  .bottom-text {
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    .whr-footer-message-class {
      .logo-class{
        width: 80%;
        margin-left:10px;
      }
    }
      
      .whr-footer-social-class{
        margin-left: 0;
        width: 100%;
        
        .title-class {
          width: 100%;
          text-align: center;
          margin: 20px 0px 10px 0px;
          font-family: AlimamaShuHeiTi;
        }
        .phone-class{
          justify-content: space-between;
        }
        .social-logo {
          width: 50px;
          height: 50px;
          margin: 5px;
          cursor: pointer;
        }
      }
      .bottom-class {
        padding: 5px 0;
      }
    }
}
</style>
