<template>
  <div class="select-content-class">
    <!-- <div class="title-class">聚善为缘</div>
    <div class="title-class">愿人人都健康 平安 快乐 幸福</div> -->
    <div class="title-class" v-if="cy_site">{{cy_site.title}}</div>
    <div class="sub-title-class">一个小小的拥抱,一份希望的开始</div>
    <div class="flex-class email-class" :style="wSize.width > 800 ? 'margin: 30px 30%;' : 'margin: 30px 10%;'">
      <input
        placeholder="您的邮箱"
        v-model="email"
        class="input-class"
      ></input>
      <div class="line-class"></div>
      <div class="button-class" @click="sendEmail">点击订阅</div>
    </div>
    <div class="desc-title-class">
      O 订阅我们的项目进展，接收有关基金会更多资讯。订阅表示您已阅读并同意我们的
      隐私政策
    </div>
  </div>
</template>
<script>
import { useWindowSize } from "@vueuse/core";
import { MenuApi } from "@/api/nav";

const subscriptionEmail = async (params) => {
  const data = await MenuApi.subscriptionEmail(params);
  return data;
};
export default {
  data() {
    return {
      email: "",
      wSize: useWindowSize(),
      cy_site:{}
    };
  },
  mounted() {
    this.cy_site = JSON.parse(localStorage.getItem("cy_site"));
  },
  methods: {
    async sendEmail() {
      if (this.email === '') {
        this.$message.error("请输入邮箱");
        return;
      }
      const data = await subscriptionEmail({
        email: this.email,
      });
      if (data.code === 0) {
        this.$message({
          message: "订阅成功",
          type: "success",
        });
        this.email = ''
      } else {
        this.$message({
          message: "订阅失败,请重试",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-content-class {
  background-color: #fafafa;
  padding: 30px;
  .title-class {
    font-size: 4vw;
    color: var(--primaryColor);
    // height: 80px;
    line-height: 80px;
    font-family: AlimamaShuHeiTi;
  }
  @media screen and (min-width: 1800px) {
    .title-class {
      line-height: 100px;
    }
  }
  @media screen and (max-width: 800px) {
    .title-class {
      line-height: 30px;
    }
  }
  
  .sub-title-class {
    font-size: 2vw;
    color: #000;
    margin-top: 30px;
  }
  .email-class {
    // width: 80%;
    margin: 30px 10%;
    font-family: AlimamaShuHeiTi;
    border-width: 2px;
    border-style: solid;
    border-color: var(--primaryColor);
    height: 60px;
    border-radius: 30px;
    padding: 0 20px;
    .input-class {
      border: none;
      font-family: AlimamaShuHeiTi;
      width: 100%;
      font-size: 1.5vw;
      height: 60px;
      color:rgba(0,0,0,0.4);
      background-color: rgb(0 0 0 / 0%);
    }
    .input-class:focus{
      border:none;
      outline: none;
    }
    .button-class{
      font-family: AlimamaShuHeiTi;
      color: var(--primaryColor);
      font-size: 1.5vw;
      min-width: 100px;
      cursor: pointer;
    }
    .button-class:hover{
      opacity: 0.8;
    }
    .line-class{
      min-width:2px;
      background-color: var(--primaryColor);
      height: 30px;
      margin: 0 30px;
    }
  }
  @media screen and (max-width: 800px) {
    .sub-title-class {
      font-size: 4vw;
      color: #000;
      margin-top: 30px;
    }
    .email-class {
      .input-class {
        font-size:3vw;
      }
      .button-class{
        font-size:3vw;
      }
    }
  }
  .desc-title-class {
    font-size: 12px;
    color: rgba($color: #000000, $alpha: 0.5);
    margin-top: 30px;
  }
  .flex-class {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
